import moment from 'moment'
import React from 'react'
import { useContext } from 'react'
import ReadMore from '../../../../components/ReadMoreText'
import eventContext from '../../../../contexts/EventContext'
import Link from "next/link";
import { convertTimezone } from '../../../../helpers/common'

const EventTitlePro = ({ eventUrl, currentUrl, buttonText, hasCoupons, ticketData }) => {

    const { eventData } = useContext(eventContext)

    const getDate = (date, format) => {
        return moment(convertTimezone(date, eventData.time_zone)).format(format);
    }

    let event_start_timestamp = `${eventData.start_date} ${eventData.start_time}`;
    let event_end_timestamp = `${eventData.end_date} ${eventData.end_time}`;
    const localTimezone = moment.tz.guess();

    const getTimeZone = () => {
        if (date.timezone.includes("+")) {
            return `GMT${date.timezone}`
        } else {
            return date.timezone;
        }
    }

    const date = {
        start_month: moment.utc(event_start_timestamp).local().format("MMM Do"),
        end_month: moment.utc(event_end_timestamp).local().format("MMM Do"),
        start_day: moment.utc(event_start_timestamp).local().format("ddd"),
        end_day: moment.utc(event_end_timestamp).local().format("ddd"),
        start_year: moment.utc(event_start_timestamp).local().format("YYYY"),
        end_year: moment.utc(event_end_timestamp).local().format("YYYY"),
        start_time: moment.utc(event_start_timestamp).local().format("h:mm A"),
        end_time: moment.utc(event_end_timestamp).local().format("h:mm A"),
        timezone: moment.tz(event_end_timestamp, localTimezone).format("z"),
    };

    const isSameDate = (dateValue) => {
        if ((dateValue.start_month == dateValue.end_month) && (dateValue.start_year == dateValue.end_year)) {
            return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time} to ${dateValue.end_time} ${getTimeZone()}`
        } else {
            return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time} - ${dateValue.end_month}, ${dateValue.end_year} ${dateValue.end_time} ${getTimeZone()}`
        }
    }

    const isSameDateWithOutYear = (dateValue) => {
        if (dateValue.start_month == dateValue.end_month) {
            return `${dateValue.start_month} ${dateValue.start_time} to ${dateValue.end_time} ${getTimeZone()}`
        } else {
            return `${dateValue.start_month} ${dateValue.start_time} - ${dateValue.end_month} ${dateValue.end_time} ${getTimeZone()}`
        }
    }


    const checkIfDisabled = (buttonText) => {
        if (buttonText === "Sold Out" || buttonText === "Expired") {
            return "disabled-btn-title"
        } else return ""
    }

    return (
        <>
            <div className="col-xl-6 col-md-12  px-0 d-flex flex-column  position-relative ms-0 ms-sm-3">
                <div className='col-md-12' >
                    <h2 className="event-title col-md-12 col-12 col-xl-12 mt-3">
                        <ReadMore
                            maxChar={"auto"}
                            content={eventData.name}
                            forcedHeight="min-content"
                            buttonTextClassName='display-6'
                            buttonTextStyle={"font-size: 25px"}
                        />
                    </h2>
                </div>
                <span className="event-date mt-1 d-none d-sm-block ">{isSameDate(date)}</span>
                <span className="event-date d-block  d-sm-none mt-1 ">{isSameDateWithOutYear(date)}</span>
                {(eventData.city && !eventData.is_virtual) && <Link href={`${currentUrl}#venue`} scroll={false}>
                    <span style={{ cursor: "pointer" }} className='  my-sm-3 ms-sm-0 mt-3'> <i style={{ fontSize: '26px', color: '#FDB14B' }} className="fa fa-map-marker-alt location-icon" aria-hidden="true"></i> <span className="event-city   ">{eventData.city}</span></span>
                </Link>}
                {(eventData.is_virtual && eventData.event_live_link) ? (
                    <a href={eventData.event_live_link} target='_blank' className='text-decoration-none text-dark mt-3'>
                        <span style={{ cursor: "pointer" }}>
                            <i style={{ fontSize: '26px', color: '#FDB14B' }} className="fa-solid fa-globe" aria-hidden="true"></i> <span className="event-city   ">Online</span>
                        </span></a>) : (eventData.is_virtual && !eventData.event_live_link) ? (
                            <span className='mt-sm-3 mt-1' >
                                <i style={{ fontSize: '26px', color: '#FDB14B' }} className="fa-solid fa-globe location-icon" aria-hidden="true"></i> <span className="event-city   ">Online</span>
                            </span>
                        ) : ""}
                {/* <span className="event-time mt-5 ms-1">{date.start_time} - {date.end_time} {date.timezone}</span> */}

                <div className="row mb-0 ms-0 me-0 mb-sm-5 w-100 d-flex row btn-container mt-2 mt-sm-4 justify-content-start pe-0 g-3 pe-sm-5 event-links">

                    {(ticketData?.length > 0 || hasCoupons) && <div className='by-btn'> <Link href={`${currentUrl}#tickets`} scroll={false}>
                        <div className={`col-12   ms-0 ms-sm-2 buy-btn-2 buy-btn d-flex align-items-center justify-content-center rounded p-2 p-sm-3 ${checkIfDisabled(buttonText)}`}>
                            <a className={`site-link-text text-white text-center text-decoration-none ${checkIfDisabled(buttonText)}`}>{buttonText}</a>
                        </div>
                    </Link></div>}

                    {eventData.event_website ?
                        <a href={eventData.event_website} target="_blank" className=" site-link-text text-center text-decoration-none link-off-website  mb-md-0">
                            <div className="col-12 ms-0 ms-sm-2  buy-btn d-flex align-items-center text-nowrap event_web-off justify-content-around rounded p-2 p-sm-3  ">
                                Official Website
                                <i className="fa fa-external-link fa-lg ms-2 website-icon" aria-hidden="true"></i>
                            </div>
                        </a>
                        : null}
                </div>
            </div>
            <style>{`
   
    .event-title{
        font-family: Prompt;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        color: #1F2439;
        word-break: break-word !important;
    }
    .disabled-btn-title {
        background: #a09f9f !important;
        cursor: not-allowed;
        pointer-events: none;
        color:#1F2439;
    }
    .event-title *{
        line-height: 63px !important;
    }
    .event-city{
        // font-family: Caveat Brush;
        // font-style: normal;
        // font-weight: normal;
        font-size: 24px;
        line-height: 130%;
         color: #1F2439;
    }
    .event-date{
        font-family: Prompt;
        font-size: 24px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        color: #1F2439;
        max-width: 100%;
    }
  
    .link-off-website > div {
        color: #1F2439;
    }

    .link-off-website:hover div {
        color: black;
    }
   
    .event-time{
        font-family: Prompt;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #1F2439;
    }
    .site-link-btn{
        display: flex;
        width: 250px;
        cursor: pointer;
        padding-left: 10px;
        padding-right: 10px;
        box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
    }
    .site-link-btn:active{
        box-shadow: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .site-link-btn:hover{
        background: #F63C3D;
        border: 3px solid #F63C3D !important;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .site-link-text{
        font-family: Prompt;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.04em;
        display: inline-block;
        padding: 0px;
        width: auto;
    }
    .by-btn{
        width:fit-content;
        padding-left:0px;
        padding-right:0px;
    }
    .buy-btn:hover a{
        color: white !important;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .buy-btn{
        width: auto;
        min-width: 180px;
        background-color: #FDB14B;
        cursor: pointer;
        box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
    }
    .buy-btn a{
        color: #1F2439 !important;
    }
    .buy-btn:active{
        box-shadow: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

     .event_web-off{
         background-color:#f6f6fc;
         border: 1px solid #1F2439;
        }
     
    @media only screen and (max-width: 576px) {
        .event-title *{
            font-size: 18px;
            line-height: 23.4px !important;
            letter-spacing: 0.4px;
            font-weight:600 !important;
        }
        .by-btn{
            width:35% !important;
            
        }
        .event-date{
            font-size: 35px;
        }
        // .site-link-btn{
        //     display:none;
        // }
        .event-date{
            font-size: 14px;
            line-height: 21.17px;
            font-weight: 400;
        }
        .site-link-text {
            width: 100%;
        }
        .event-links{
           min-width: 260px;
           
        }

        .event_web-off{
            justify-content: center !important;
            gap: 10px;
        }
        .event-city{
            font-size: 14px !important;
            font-weight: 400;
        }
        .location-icon{
            font-size: 15px !important;
          }
        .website-icon{
            font-size: 18px;
          
          }
        .site-link-text{
            font-size: 16px;
        }
        .buy-btn{
            min-width: fit-content;
            box-shadow: 0 0 0;
        }
        .buy-btn-2{
           //width: 40% !important;
            white-space:nowrap;
        }
        .btn-container{
            flex-wrap:nowrap;
            gap:17px;
        }
    }
        @media only screen and (max-width: 365px) {
            .btn-container{
                flex-direction:column;
                gap:3px;
            }
            .buy-btn-2{
            width: 100% !important;
        }
            .site-link-text{
                width: 100% ;
            }
             .by-btn{
                width:100% !important;
            }
        }
        `}
            </style>
        </>
    )
}

export default EventTitlePro