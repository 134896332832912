import React from 'react'
import { useContext, useEffect, useRef } from 'react'
import eventContext from '../../../contexts/EventContext'
import ReadMore from '../../../components/ReadMoreText'
import useOnScreen from '../../../components/useOnScreen'
import { get } from 'lodash'
import { getTranslation } from '../../../helpers/common'


const EventAboutPro = ({ setCurrSection, matchedEventTranslationConfig }) => {

    const { eventData } = useContext(eventContext)


    return (
        <>
            <div className='w-100 d-flex justify-content-center align-items-center mt-3 mt-lg-5 mt-md-5'>
                <div className='content py-4 px-4 w-full  w-75'>
                    <div className='d-flex justify-content-start justify-content-sm-center'>
                        <div>
                            <img className='about-img' src="/img/about-event.svg" alt="" />
                            <p className='about-heading'>  {matchedEventTranslationConfig?.match ?  getTranslation('About the Event', matchedEventTranslationConfig?.translateTo) : "About the Event"}</p>
                        </div>
                    </div>

                    <p className='about-text text-start mt-0 mt-sm-4' style={{color:'#141B25',lineHeight:"24.19px"}}>
                        {eventData.description && <div className=" text-start fw-normal" onClick={(e) => e.stopPropagation()} style={{lineHeight:"24.19px"}}>
                            <ReadMore
                                content={eventData.description}
                                maxChar={"auto"}
                                styles={{
                                    fontFamily: "Prompt",
                                    margin: "0px",
                                    fontWeight:'400',
                                    lineHeight:"24.19px",

                                }}
                            />
                        </div>}
                    </p>
                </div>
            </div>
            <style jsx >{`

                p {
                    margin-bottom: 0px;
                }

                .about-text *{
                    font-family: Prompt !important;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0.04em;
                    text-align: left;
                    margin-bottom: 0px !important;
                }
                .about-img{
                    margin-bottom: -35px;
                    margin-left: -15px;
                }

                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #1C2520;
                }
                @media only screen and (max-width: 768px) {
                    .about-heading{
                        font-size: 42px;
                    }
                }

                @media only screen and (max-width: 576px) {
                    .about-heading{
                        font-size: 18px;
                        font-weight: 600;
                       // line-height: 18px;
                    }
                    .about-img{
                        margin-bottom: -63px;
                        margin-left: 0px;
                        width: 44.69px;
                        height: 35px;
                        display:none;
                    }
                    .about-text{
                        //margin-top: 1rem !important;
                        line-height:24.19px !important;
                    }
                    .about-text *{
                        font-size:16px;
                    }
                    .w-full{
                        width: 100% !important;
                    }
                }
                
            `}</style>
        </>
    )
}

export default EventAboutPro