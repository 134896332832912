import moment from 'moment';
import React, { useEffect, useContext, useState, useRef } from 'react'
import eventContext from '../../../contexts/EventContext';
import { getWorkshops } from '../../../crud/dedicatedEvent-crud';
import WorkshopCard from '../../../components/WorkshopCard';
import ReadMore from '../../../components/ReadMoreText';
import useOnScreen from '../../../components/useOnScreen';
import { sampleWorkshopsData } from '../../../data/sampleEvent';
import { useInView } from 'react-intersection-observer';

const EventWorkshopsPro = ({ eventUrl, setCurrSection, samplePage }) => {

    const [workshops, setWorkshops] = useState(null);
    const [showAll, setshowAll] = useState(false);

    const { eventData } = useContext(eventContext)


    const getWorkshopsFunction = async () => {
        try {
            const res = await getWorkshops(eventUrl);
            setWorkshops(res.data);
        } catch (error) {
            errorToaster("Something went wrong while trying to get ticket, please try again later");
        }
    }

    useEffect(() => {
        if (samplePage) {
            setWorkshops(sampleWorkshopsData)
        } else {
            getWorkshopsFunction();
        }
    }, [eventUrl])



    function convertToHTML(workshopValue) {
        return { __html: `${workshopValue.workshop_description}` };
    }


    const getDate = (date, format) => {
        return moment(convertTimezone(date, eventData.time_zone)).format(format);
    }

    const isSameDate = (workshopValue) => {
        let start_month = getDate(`${workshopValue.start_timestamp}`, 'MMM Do');
        let end_month = getDate(`${workshopValue.end_timestamp}`, 'MMM Do');
        let start_year = getDate(`${workshopValue.start_timestamp}`, 'YYYY');
        let end_year = getDate(`${workshopValue.end_timestamp}`, 'YYYY');
        let start_time = getDate(`${workshopValue.start_timestamp}`, "h:mm A");
        let end_time = getDate(`${workshopValue.end_timestamp}`, "h:mm A");
        let timezone = moment.tz(`${workshopValue.end_timestamp}`).format("z");
        if ((start_month == end_month) && (start_year == end_year)) {
            return `${start_month}, ${start_year} ${start_time} to ${end_time} ${timezone}`
        } else {
            return `${start_month}, ${start_year} ${start_time} - ${end_month}, ${end_year} ${end_time} ${timezone}`
        }
    }


    function workshopClass(i) {
        let workshopCount = workshops.length - 1 //total workshop indexes

        if (workshops[i].workshop_banner_url) return "col-12" //If workshop has banner
        else if (!workshops[i].workshop_banner_url && i - 2 >= 0) { //If workshop doesn't have banner and has two previous worshops
            if (!workshops[i - 1].workshop_banner_url && !workshops[i - 2].workshop_banner_url) { //previous both dont have image then the current one should take full widht
                return "col-12"
            } else if (!workshops[i - 1].workshop_banner_url) { // if the previous one only dont have image then the current should be half
                return "col-md-6 col-12"
            } else if (i + 1 <= workshopCount && !workshops[i + 1].workshop_banner_url) { //else if the next one has no image
                return "col-md-6 col-12"
            }
        } else if (!workshops[i].workshop_banner_url && i + 1 <= workshopCount && !workshops[i + 1].workshop_banner_url) {//else if there are no previous workshops and the next one has no image
            return "col-md-6 col-12"
        } else if (!workshops[i].workshop_banner_url && i + 1 <= workshopCount && workshops[i + 1].workshop_banner_url) {  //else if there are no previous workshops and the next one has image
            return "col-12"
        }
    }

    return (
        <>
            <div className='w-100 d-flex justify-content-center align-items-center mt-0 mt-lg-5 mt-md-5 pb-2'>
                <div className='content py-sm-4 pt-1 pb-2 px-4 w-100 d-flex justify-content-center flex-column align-items-sm-center align-items-start'>
                    <div className='head'>
                        <img className='about-img' src="/img/about-event.svg" alt="" />
                        <p className='about-heading'>{eventData?.workshop_section_title ? eventData?.workshop_section_title : 'Tech Workshops'}</p>
                    </div>
                    {
                        eventData?.workshop_section_description &&
                        <div className={`d-flex w-sm-90 w-full px-sm-2 px-0 justify-content-center mt-sm-4 mt-2 text-sm-center text-start`} style={{ color: '#1F2439',}}>
                            <ReadMore
                                content={eventData.workshop_section_description}
                                maxChar={"auto"}
                                styles={{
                                    fontFamily: "Prompt",
                                    textAlign: "center",
                                    fontSize:'16px',
                                    
                                    fontWeight:'400',
                                    color: '#1F2439',
                                }}
                            />
                        </div>
                    }
                    <div className='d-flex flex-wrap px-sm-3 px-0 f-gap w-100 justify-content-center'>
                        {workshops &&
                                workshops.map(workshopValue => (
                                    <div className="mt-sm-5 mt-2 workshop-card-if-only-one">

                                    <WorkshopCard workshopDetails={workshopValue} eventUrl={eventUrl} color="#FDB14B" />
                                    </div>

                                ))}
                    </div>
                    {/*workshops && workshops.length > 4 && (
                        <div className="col-12 mt-4 mb-3 view-btn d-flex align-items-center justify-content-center rounded mt-4 py-3 px-3 ">
                            <a onClick={() => setshowAll(true)} className="site-link-text text-white text-center text-decoration-none">View All</a>
                        </div>
                    )*/}
                </div>
            </div>
            <style jsx >{`

            .view-btn{
                height: 48.240482330322266px;
                width: 132.680419921875px;
                border-radius: 5px;
                background: #f6f6fc;
                cursor: pointer;
                border: 2px solid #C4C4C4;
                border-radius: 5px;
            }

            .w-90{
                width: 90%;
            }

            .workshop-card-if-only-one{
                    max-width: 500px;
                    width:100%;
                    display: flex;
                    padding:10px 8px;
                    justify-content: center;
                }
                .f-gap{
                    gap:15px;
                }

                .site-link-text{
                    font-family: Prompt;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #1F2439 !important;
            }

                .about-img{
                       margin-bottom: -51px;
                       margin-left: -28px;
                }
               
                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #1C2520;
                }
                body{
                    color: #1F2439 !important,
                }

                @media only screen and (max-width: 768px) {
                
                .about-heading{
                    font-size: 42px;
                }
                    
                }
                @media only screen and (max-width: 576px) {
                    .workshop-card-if-only-one{
                        max-width: 350px;
                        padding: 0px 2px;
                    }
                    .about-heading{
                        font-size: 18px;
                        font-weight: 600;
                        text-align: start;
                        //margin-left: 21px;
                        //margin-top: -9px;
                    }
                    .about-img{
                        margin-bottom: -63px;
                        margin-left: 0px;
                        width: 44.69px;
                        height: 35px;
                        display:none;
                }
                    .view-btn{
                        padding: 8px 25px !important;
                        border-radius: 5px !important;
                    }
                }
                

            `}</style>
        </>
    )
}

export default EventWorkshopsPro