import React from 'react'
import { useEffect, useState, useRef } from 'react';
import ReadMore from '../../../components/ReadMoreText';
import useOnScreen from '../../../components/useOnScreen';
import { getSpeakers } from '../../../crud/dedicatedEvent-crud';
import SpeakerCardPro from './SpeakerCardPro'
import { sampleSpeakersData } from '../../../data/sampleEvent';
import { errorToaster, isValidDescription } from '../../../helpers/utils';
import { useInView } from 'react-intersection-observer';

const EventSpeakersPro = ({ eventUrl, eventData, setCurrSection, samplePage , matchedEventTranslationConfig }) => {

    const [speakers, setSpeakers] = useState([]);

    const getAllSpeakers = async () => {
        try {
            const res = await getSpeakers(eventUrl);
            let speakerData = res.data.sort((a, b) => {
                return a.speaker_order - b.speaker_order
            });
            setSpeakers(speakerData);
        } catch (error) {
            errorToaster("Something went wrong while trying to get ticket, please try again later");
        }
    }

    useEffect(() => {
        if (samplePage) {
            setSpeakers(sampleSpeakersData);
        } else {
            getAllSpeakers()
        };
    }, [eventUrl])

    return (
        <>
            <div className='w-100 d-flex justify-content-center align-items-center mt-0 mt-lg-5 mt-md-5 pb-2'>
                <div className='content p-4 py-sm-4 w-100 d-flex justify-content-center flex-column align-items-sm-center align-items-start'>
                    {(isValidDescription(eventData?.speaker_section_description) || eventData.has_speakers) &&
                        <div className='content py-sm-4 pt-1 pb-2 px-4 w-100 d-flex justify-content-center flex-column align-items-sm-center align-items-start'>
                            <div className='head'>
                                <img className='about-img' src="/img/about-event.svg" alt="" />
                                <p className='about-heading'>{eventData?.speaker_section_title ? eventData?.speaker_section_title : 'Event Speakers'}</p>
                            </div>
                            <div className={`d-flex w-full w-sm-90 px-sm-2 px-0  justify-content-center mt-sm-4 mt-1 speaker_section_description  fw-normal`} onClick={(e) => e.stopPropagation()}>
                                <ReadMore
                                    content={eventData.speaker_section_description}
                                    maxChar={"auto"}
                                    styles={{
                                        fontFamily: "Prompt",
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        color: '#1C2520',
                                    }}
                                />
                            </div>
                        </div>
                    }
                    <div className='d-flex flex-wrap mt-0 mt-sm-3 w-100 justify-content-sm-center justify-content-start '>
                        {speakers.map(speaker => (
                            <SpeakerCardPro speaker={speaker} matchedEventTranslationConfig={matchedEventTranslationConfig} />
                        ))}
                    </div>
                </div>
            </div>
            <style jsx >{`

                .about-img{
                  margin-bottom: -51px;
                  margin-left: -28px;
                }
                .w-90{
                    width: 90%;
                }
                
                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                }
                .speaker_section_description{
                        color: #1F2439;
                }
                @media only screen and (max-width: 768px) {
                    .about-heading{
                        font-size: 42px;
                    }
                }
                @media only screen and (max-width: 576px) {
                    .about-heading{
                        font-size: 18px;
                        font-weight: 600;
                        color: #1C2520;
                        //margin-left: 21px;
                        //margin-top: -9px;
                    }
                    .about-img{
                        margin-bottom: -63px;
                        margin-left: 0px;
                        width: 44.69px;
                        height: 35px;
                        display:none;
                    }
                    
                }

            `}</style>
        </>
    )
}

export default EventSpeakersPro