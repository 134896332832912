import React, { useState, useEffect, useRef } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import eventContext from "../../../contexts/EventContext";
import Loading from "../../../components/Loading";
import NavbarPro from "../../../components/NavigationBar/NavbarPro";
import EventHeadPro from "./EventHead/EventHeadPro";
import EventAboutPro from "./EventAboutPro";
import EventPricingPro from "./EventPricing/EventPricingPro";
import EventSpeakersPro from "./EventSpeakersPro";
import EventWorkshopsPro from "./EventWorkshopsPro";
import EventFooterPro from "./EventFooterPro";
import EventSponsorsPro from "./EventSponsorsPro";
import EventPartnersPro from "./EventPartnersPro";
import EventExhibitorsPro from "./EventExhibitorsPro";
import EventVenuePro from "./EventVenuePro";
import EventShoutPro from "./EventShoutPro";
import Link from "next/link";
import AgendaPro from "./AgendaPro/AgendaPro";
import { getTicketData } from "../../../crud/dedicatedEvent-crud";
import { pricingSampleData } from "../../../data/sampleEvent";
import _ from "lodash";
import moment from "moment";
import { errorToaster } from "../../../helpers/utils";
import { useSectionRefs } from "../../../config/customHooks";
import { getEventTranslationConfig } from "../../../helpers/common";

const EventPagePro = ({
	isReady,
	eventUrl,
	query,
	eventData,
	samplePage = false,
	ticketsData,
	groupDiscounts
}) => {
	// const [eventData, setEventData] = useState(eventData)
	const [currentUrl, setCurrentUrl] = useState("");
	const [CurrSection, setCurrSection] = useState("about");
	const [ticketData, setTicketData] = useState(ticketsData);
	const router = useRouter();
	let newQuery = { ...query };
	delete newQuery.eventUrl;
	const faviconIconUrl =
		eventData.fav_icon_urls && eventData.fav_icon_urls["favicon.ico"];

    // section refs
	const { sections } = useSectionRefs();

	const matchedEventTranslationConfig = getEventTranslationConfig(eventUrl);

	useEffect(() => {
		// Scroll to view section
		const sectionId = window.location.hash.replace('#', '');
		const scrollToSectionWithDelay = () => {
			const element = sections[sectionId]?.current;
			if (element) {
				const offsetTop = element.offsetTop;
				const scrollToPosition = offsetTop - 30;
				window.scrollTo({
					top: scrollToPosition,
					behavior: "smooth",
				});
			}
		};

		if (sectionId && sections[sectionId]) {
			setTimeout(scrollToSectionWithDelay, 1000); // Wait 1 second for the page to finish rendering
		}
	}, []);

	const availableTabs = {
		about: eventData.description,
		speakers: eventData?.has_speakers,
		workshops: eventData?.has_workshops,
		sponsors: eventData?.has_sponsors,
		partners: eventData?.has_partners,
		exhibitors: eventData?.has_exhibitors,
		agenda: eventData?.has_agenda,
		venue:
			(!eventData.is_virtual && eventData.venue) ||
			eventData.event_url === "wencarnival",
	};

	const hideHighlight =
		(eventData?.expected_speakers === 0 ||
			eventData?.expected_speakers === null) &&
		(eventData?.expected_attendees === 0 ||
			eventData?.expected_attendees === null);

	let mybutton = null;
	var navbar = null;

	function scrollFunction() {
		if (
			document.body.scrollTop > 400 ||
			document.documentElement.scrollTop > 400
		) {
			mybutton.style.display = "block";
		} else {
			mybutton.style.display = "none";
		}
	}

	function scrollToSection(sectionId) {
		const element = sections[sectionId].current;
		if (element) {
			const offsetTop = element.offsetTop;
			const scrollToPosition = offsetTop - 40;
			window.scrollTo({
				top: scrollToPosition,
				behavior: "smooth",
			});
			// Update URL with fragment identifier
			const url = `${window.location.pathname}#${sectionId}`;
			window.history.replaceState({}, document.title, url);
		}
	}
	// When the user clicks on the button, scroll to the top of the document
	function topFunction() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
	function myFunction() {
		if (window.pageYOffset >= 150) {
			navbar.style.boxShadow = "rgb(196 196 196) 0px 0px 5px";
		} else {
			navbar.style.boxShadow = "none";
		}
	}

	const getTicketDataFunction = async () => {
		try {
			const res = await getTicketData(eventUrl);
			let uncategorized = res.data.uncategorized;
			let allTickets = [...uncategorized];
			res.data.categorized = res.data.categorized.sort(
				(a, b) => a.category_order - b.category_order,
			);
			res.data.categorized.forEach((category) => {
				category.tickets = category.tickets.map((ticket) => {
					ticket.category = {
						category_name: category.category_name,
						category_description: category.category_description,
						category_id: category.category_id,
						category_order: category.category_order,
						is_expanded: category.is_expanded,
					};
					return ticket;
				});
				allTickets = [...allTickets, ...category.tickets];
			});
			let ticketDetails = allTickets;
			let orderedTicket = ticketDetails.sort((a, b) => {
				// Sort the forms by from orderzz
				return a.ticket_order - b.ticket_order;
			});
			let newTickets = [];
			orderedTicket.map((ticket) => {
				if (!ticket.hidden_ticket) newTickets.push(ticket);
				else return;
			}); // temporary
			setTicketData(newTickets);
		} catch (err) {
			console.log(err);
			errorToaster(
				"Something went wrong while trying to get ticket, please try again later",
			);
		}
	};
	useEffect(() => {
		mybutton = document.getElementById("scrollDiv");
		window.onscroll = function () {
			scrollFunction(), myFunction();
		};
		navbar = document.getElementById("sticky");
	}, []);

	useEffect(() => {
		if (samplePage) {
			setTicketData(pricingSampleData.uncategorized);
		} else {
			//getTicketDataFunction();
		}
	}, [eventUrl]);

	const isTicketSoldOut = () => {
		var todaysTime = moment().valueOf();
		let event_end_timestamp = `${eventData.end_date} ${eventData.end_time}`;
		let eventEndTime = moment.utc(event_end_timestamp).local().valueOf();
		if (!_.isEmpty(ticketData)) {
			let soldoutTickets = ticketData.filter(
				(ticket) =>
					(ticket.sold_out === true || ticket.remaining_count <= 0) &&
					!ticket.waitlist_enabled,
			);
			if (soldoutTickets.length === ticketData.length) {
				return "Sold Out";
			}
		}
		if (eventData.is_free) {
			return "Register";
		} else if (todaysTime > eventEndTime) {
			return "Expired";
		} else return "Buy Now";
	};

	return (
		<>
			<eventContext.Provider value={{ eventData }}>
				{Object.keys(eventData).length !== 0 ? (
					<>
						{faviconIconUrl ? (
							<Head>
								<link rel="icon" href={faviconIconUrl} />
							</Head>
						) : (
							<Head>
								<link rel="icon" href="./favicon/globe.ico" />
							</Head>
						)}
						<div className="container-color">
							<div
								id="scrollDiv"
								style={{
									display: "none",
									background: "#f6f6fc",
									borderRadius: "50%",
									boxShadow: "0px 0px 7px rgb(77 77 77)",
								}}
								onClick={topFunction}
								className="icon-scroll"
							>
								<img src="/img/scroll-top.svg" />
							</div>
							<div
								className="sticky"
								id="sticky"
								style={{ backgroundColor: "#f6f6fc" }}
							>
								<NavbarPro
									availableTabs={availableTabs}
									CurrSection={CurrSection}
									setCurrSection={setCurrSection}
									disableLogo={eventUrl == "linkedin" ? true : false}
									mobileNavbar={false}
									eventPage={true}
									ticketData={ticketData}
									scrollToSection={scrollToSection}
									matchedEventTranslationConfig={matchedEventTranslationConfig}
								/>
							</div>
							<div className="content">
								<EventHeadPro
									eventUrl={eventUrl}
									hideHighlight={hideHighlight}
									currentUrl={currentUrl}
									buttonText={isTicketSoldOut()}
									hasCoupons={eventData.has_coupons}
									ticketData={ticketData}
								/>
								{eventData.description && (
									<div ref={sections.about} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("about")} className="custom-link">
										<EventAboutPro setCurrSection={setCurrSection} matchedEventTranslationConfig={matchedEventTranslationConfig} />
									</div>
								)}
								<div ref={sections.tickets} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("tickets")} className="custom-link">
									<EventPricingPro
										eventUrl={eventUrl}
										setCurrSection={setCurrSection}
										newQuery={newQuery}
										samplePage={samplePage}
										hasCoupons={eventData.has_coupons}
										hasAccessCodes={eventData.has_access_codes}
										ticketData={ticketData}
										getTicketDataFunction={getTicketDataFunction}
										setTicketData={setTicketData}
										groupDiscounts={groupDiscounts}
										matchedEventTranslationConfig={matchedEventTranslationConfig}
									/>
								</div>
								{(eventData?.speaker_section_description || !!eventData?.has_speakers) && (
									<div ref={sections.speakers} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("speakers")} className="custom-link">
										<EventSpeakersPro
											eventUrl={eventUrl}
											setCurrSection={setCurrSection}
											eventData={eventData}
											samplePage={samplePage}
											matchedEventTranslationConfig={matchedEventTranslationConfig}
										/>
									</div>
								)}
								{!!eventData?.has_workshops && (
									<div ref={sections.workshops} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("workshops")} className="custom-link">
										<EventWorkshopsPro
											eventUrl={eventUrl}
											setCurrSection={setCurrSection}
											samplePage={samplePage}
										/>
									</div>
								)}

								{!!eventData?.has_agenda && (
									<div ref={sections.agenda} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("agenda")} className="custom-link">
										<AgendaPro
											eventUrl={eventUrl}
											setCurrSection={setCurrSection}
											eventData={eventData}
											samplePage={samplePage}
										/>
									</div>
								)}
								{!!eventData?.has_sponsors && (
									<div ref={sections.sponsors} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("sponsors")} className="custom-link">
										<EventSponsorsPro
											eventUrl={eventUrl}
											setCurrSection={setCurrSection}
											eventData={eventData}
											samplePage={samplePage}
											matchedEventTranslationConfig={matchedEventTranslationConfig}
										/>
									</div>
								)}
								{!!eventData?.has_partners && (
									<div ref={sections.partners} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("partners")} className="custom-link">
										<EventPartnersPro
											eventUrl={eventUrl}
											setCurrSection={setCurrSection}
											eventData={eventData}
											samplePage={samplePage}
											matchedEventTranslationConfig={matchedEventTranslationConfig}
										/>
									</div>
								)}
								{!!eventData?.has_exhibitors && (
									<div ref={sections.exhibitors} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("exhibitors")} className="custom-link">
										<EventExhibitorsPro
											eventUrl={eventUrl}
											setCurrSection={setCurrSection}
											eventData={eventData}
											samplePage={samplePage}
											matchedEventTranslationConfig={matchedEventTranslationConfig}
										/>
									</div>
								)}
								{((!eventData.is_virtual && eventData.venue) ||
									eventData.event_url === "wencarnival") && (
										<div ref={sections.venue} onClick={(e) => { e.preventDefault(); }} onMouseEnter={() => setCurrSection("venue")} className="custom-link">
											<EventVenuePro matchedEventTranslationConfig={matchedEventTranslationConfig} setCurrSection={setCurrSection} />
										</div>
									)}
								<EventShoutPro matchedEventTranslationConfig={matchedEventTranslationConfig} />
								{/* <EventFooterPro /> */}
							</div>
						</div>
					</>
				) : (
					<div className=" vh-100 d-flex justify-content-center align-items-center bg-light align-self-center event-header-container event-header-container-image px-0">
						<Loading color="#141B25" />
					</div>
				)}
			</eventContext.Provider>
			<style jsx global>{`
		a{
			text-decoration: none;
		}	
		.custom-link {
			color: inherit;
			cursor: default;
			text-decoration: none;
		}	
		.custom-link:hover {
			color: inherit;
			cursor: default;
		}	
        .container-color {
          background: #f6f6fc;
        }
        .sticky {
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 2000;
          transition: box-shadow 0.3s ease-in-out;
        }
        .icon-scroll {
          position: fixed;
          bottom: 4%;
          right: 2%;
          z-index: 70;
        }
        .icon-scroll * {
          width: 40px;
          cursor: pointer;
          height: 40px;
        }
        .content {
          margin-top: 15px;
		  background-color:#f6f6fc;
        }
        .sticky + .content {
          padding-top: 60px;
        }
        @media only screen and (max-width: 576px) {
          .content {
          margin-top: 0px;
		  background-color:#f6f6fc;
        }
        }
      `}</style>
		</>
	);
};

export default EventPagePro;