import React, { useState } from 'react';
import { convertTimezone } from '../../../../helpers/common';
import { motion } from 'framer-motion';
import moment from 'moment';
import AgendaCard from '../../../../components/AgendaCard';

const AgendaSessions = ({ selectedTrackData, eventData }) => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedSession, setSelectedSession] = useState(false);
    const trackSessions = [...selectedTrackData[0].track_sessions];
    trackSessions?.sort((a, b) => a?.session_order - b?.session_order);
    const userTimezone = moment.tz.guess();

    const getDate = (date, format) => {
        return moment(convertTimezone(date, userTimezone)).format(format);
    };

    const date = (session) => {
        let start_time = getDate(`${session.start_timestamp}`, 'h:mm A');
        let end_time = getDate(`${session.end_timestamp}`, 'h:mm A');
        let timezone = moment.tz(`${session.end_timestamp}`, userTimezone).format('z');

        return `${start_time} - ${end_time} ${timezone}`;
    };

    function convertToHTML(session) {
        return { __html: `${session?.session_description?.replace(/(?:\r\n|\r|\n)/g, '') || ''}` };
    }

    return (
        <>
            {!_.isEmpty(trackSessions) ? (
                <>
                    {trackSessions.map((session) => (
                        <div className="d-flex flex-lg-row flex-column mb-3" key={`${session.session_id}`}>
                            <motion.button
                                className="session-details py-2 px-3 w-100 text-start bg-white"
                                style={{ border: '0.7px solid #B4B4B4', borderRadius: '0px 6px 6px 0px', borderLeft: '6px solid #FDB14B' }}
                                whileHover={{ scale: 1.03 }}
                                onClick={() => {
                                    if (session.session_description || session.session_speakers.length > 0) {
                                        setOpenModal(true);
                                        setSelectedSession(session);
                                    }
                                }}
                            >
                                <div className="mb-1 me-1 date-text">
                                    {getDate(session.start_timestamp, 'h:mm A')} - {getDate(session.end_timestamp, 'h:mm A')}
                                </div>
                                {session.session_location && (
                                    <div className="mb-1 d-flex gap-1 location-text">
                                        <img src="/img/prolocation.svg" alt="" />
                                        <p>{session.session_location}</p>
                                    </div>
                                )}
                                <div className="d-flex session-title-container d-flex justify-content-between">
                                    <p className="session-title mb-1">{session.session_title}</p>
                                </div>
                                {session.session_description && (
                                    <p
                                        className="session-description mb-0"
                                        dangerouslySetInnerHTML={{ __html: session.session_description }}
                                    />
                                )}
                                {session.session_speakers.length > 0 && (
                                    <div className="session-speakers-container mt-2">
                                        {session.session_speakers.map((speaker, index) => (
                                            <div
                                                className="speaker-agenda-container"
                                                style={{
                                                    zIndex: session.session_speakers.length - index,
                                                    left: `${index * 20}px`,
                                                }}
                                                key={speaker.id}
                                            >
                                                <img
                                                    src={speaker.image_url}
                                                    className="speaker-img-agenda rounded-circle"
                                                    alt="Speaker image"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </motion.button>
                        </div>
                    ))}
                </>
            ) : (
                <div>Sessions for this track are yet to be updated</div>
            )}
            {openModal &&
                <AgendaCard
                    isOpen={openModal}
                    closeModal={() => setOpenModal(false)}
                    sessionDetails={selectedSession}
                    professionalTemplate={true}
                />
            }

            <style jsx>{`
        .session-title {
          font-family: 'Prompt';
          font-size: 16px;
          font-weight: 500;
          color: #141b25;
        }
        .session-description {
          font-family: 'Prompt';
          font-size: 16px;
          font-weight: 400;
          color: #474747;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .speaker-img-agenda {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 2px solid #fff;
        }
        .session-speakers-container {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 40px;
          width: 100%;
          overflow: hidden;
        }
        .speaker-agenda-container {
          position: absolute;
        }
        .date-text {
          font-weight: 400;
          font-size: 15px;
          color: #fdb14b;
        }
        .location-text {
          font-size: 15px;
          font-weight: 400;
          color: #141b25;
        }
      `}</style>
        </>
    );
};

export default AgendaSessions;
